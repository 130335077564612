<template>
  <div v-if="!loading">
    <rates-form
      ref="rates-form"
      @fetchData="fetchData"
    />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ratesModule from '@/store/main/catalog/rates'
import Skeleton from './components/Skeleton.vue'
import RatesForm from './components/RatesForm.vue'
import config from './ratesConfig'

export default {
  name: 'RatesCreate',
  computed: {
    ...mapState('listModule', { priceTiers: 'priceTierList' }),
    ...mapState('rates', ['rates']),
    ...mapGetters('rates', ['getRateByTierId']),
  },
  created() {
    this.$emit('updateMenu', 'home-catalog-rates')
    this.fetchData()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['rates-form']) this.$refs['rates-form'].showConfirmationModal(next)
  },
  methods: {
    ...mapActions('listModule', ['priceTierList']),
    ...mapActions('rates', ['getRates']),
    ...mapMutations('rates', ['SET_RATES_FORM']),
    ...mapMutations('cloneData', ['SET_RATES_FORM_CLONE']),
    fetchData() {
      this.loading = true
      Promise.all([this.getRates(), this.priceTierList({})])
        .then(() => {
          const list = {}
          const processedRatesData = this.rates
          this.priceTiers.forEach(priceTier => {
            const select = this.getRateByTierId(priceTier.id)
            let data = {
              pricing_rule_id: null,
              pricing_type_id: null,
              rate: null,
              price_tier_id: priceTier.id,
              pricing_period_days: null,
              price_tier_override: false,
            }
            data = select ?? data

            if (processedRatesData.length === 1) {
              const theRateData = processedRatesData[0]
              data.rate = this.getRateForLabel(priceTier.label, theRateData)
            }

            list[priceTier.id] = this.mappingFields(Object.keys(this.fields), data, {
              pricing_rule_id: data.pricing_rule ?? null,
              pricing_type_id: data.pricing_type ?? null,
              rate: data.rate ? Number(data.rate) : null,
            })
          })
          this.SET_RATES_FORM(list)
          this.SET_RATES_FORM_CLONE(list)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRateForLabel(label, rateData) {
      // this function is for getting the values of newly imported Rates to the server
      // there are only three types of Rates on the server, which the function checks
      switch (true) {
        case label.toLowerCase().includes('weekly'):
          return rateData?.weekly_rate ? Number(rateData.weekly_rate) : null
        case label.toLowerCase().includes('weekend'):
          return rateData?.weekend_rate ? Number(rateData.weekend_rate) : null
        case label.toLowerCase().includes('daily'):
          return rateData?.daily_rate ? Number(rateData.daily_rate) : null
        default: console.warn('No matching case for label:', label)
          return null
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  components: {
    Skeleton,
    RatesForm,
  },
  setup() {
    const MODULE_NAME = 'rates'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, ratesModule)

    const { fields } = config()
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    const loading = ref(true)
    return {
      MODULE_NAME,
      loading,
      fields,
    }
  },
}
</script>
