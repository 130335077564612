import store from '@/store'
import axios from '@/libs/axios'

const endpoint = () => `products/${store.state.catalogs?.catalog?.id}/rates`

const getRates = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(`/${endpoint()}`, { params: queryParams })
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const update = (ctx, data) => axios.put(`/${endpoint()}`, data)

export default {
  getRates,
  update,
}
