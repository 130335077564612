<template>
  <div>
    <div
      v-for="item in Array(4)"
      :key="item"
    >
      <div class="w-25">
        <b-skeleton
          height="20px"
          style="border-radius: 5px"
        />
      </div>
      <b-row>
        <b-col md="6">
          <div
            v-for="field in ['rate', 'pricing_rule_id']"
            :key="field"
            style="height: 70px"
          >
            <span class="inputLabel">{{ fields[field].label }}</span>
            <b-skeleton
              height="40px"
              style="border-radius: 5px"
            />
          </div>
        </b-col>
        <b-col md="6">
          <div
            v-for="field in ['pricing_period_days', 'pricing_type_id']"
            :key="field"
            style="height: 70px"
          >
            <span class="inputLabel">{{ fields[field].label }}</span>
            <b-skeleton
              height="40px"
              style="border-radius: 5px"
            />
          </div>
          <div
            v-for="field in ['price_tier_override']"
            :key="field"
            style="height: 25px"
            class="d-flex align-items-center justify-content-between"
          >
            <span class="inputLabel">{{ fields[field].label }}</span>
            <b-skeleton
              height="18px"
              width="18px"
              style="border-radius: 5px; margin-bottom: 0"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import config from '../ratesConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  computed: {
    ...mapGetters('listModule', ['getActivePriceTierList']),
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
